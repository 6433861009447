.footer {
  background: $corpGray;
  padding: 8% 20px 0;
  text-align: center;

  @include mq(md) {
    padding: 5% 80px 0;
    text-align: left;
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $darkestGray;
    margin: 0;
    padding: 40px 0;
    justify-content: space-evenly;

    @include mq(md) {
      justify-content: flex-start;
    }

    li{
      margin-left: 7px;
      margin-right: 7px;

      @include mq(sm) {
        margin-left: 0;
        margin-right: 30px;
      }

      a {
        font-size: 14px;
        color: $white;
        font-weight: 700;
        text-decoration: none;
      }
    }
  }

  &__copy {
    font-size: 14px;
    color: $midGray;
    margin: 0;
    padding: 30px 0;
    font-weight: 700;
  }
}
