$white: #fff;
$black: #020100;
$corpGray: #4c4c4c;
$darkestGray: #333;
$darkGray: #787871;
$midGray: #999;
$lightGray: #ebebeb;
$lightestGray: #f2f2f2;
$blue: #053f7a;
$blueGray: #bbc9c9;

$mainColor: $black;
$link: $mainColor;
$linkHover: $mainColor + #333;
$text: $white;
$textDark: $black;
$accent: $mainColor;
$bg: $white;
$bgSecondary: $mainColor;
$bgDark: $black;

$base: 40px;
$radius: 20px;

$head: 60px;
$xxl: 36px;
$xl: 24px;
$lg: 21px;
$md: 18px;
$sm: 16px;
$xs: 14px;
$xxs: 12px;

$duration: 0.3s;
$easing: cubic-bezier(.785, .135, .15, .86);
$easingLight: ease;

$mainFont: 'Audi Type Screen', sans-serif;
$titleFont: 'Audi Type Extended', sans-serif;
