.home {
  &__hero {
    background: url('/images/main-home-mobile.jpg') no-repeat center top / cover;
    height: 75vw;

    @include mq(md) {
      height: 30vw;
      background: url('/images/main-home.jpg') no-repeat center top fixed / 100%;
    }
  }

  .text-center {
    text-align: center;
  }

  &__main {
    padding: 10% 0;
    background: white;
    text-align: center;
    color: $mainColor;

    @include mq(md) {
      padding: 5% 0;
    }
  }

  &__main-title {
    font-weight: 700;
    font-family: $titleFont;
    font-size: 24px;
    line-height: 1.4;
    margin: 0 0 40px;

    @include mq(sm) {
      font-size: 28px;
    }

    @include mq(md) {
      font-size: 40px;
    }
  }

  &__main-subtitle {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 60px;

    @include mq(md) {
      font-size: 18px;
    }
  }

  &__main-cta {
    border: 1px solid $lightGray;

    @include mq(sm) {
      // display: flex;
      // align-items: center;
      // flex-wrap: wrap;
    }

    &__top {
      background: $lightestGray;
      margin-bottom: 20px;
      padding: 20px 0;
      
      @include mq(sm) {
        
        display: flex;
        justify-content: space-between;
      }
    }

    &__bottom {
      padding: 0 20px 20px;

      @include mq(sm) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 60px 60px;
        max-width: 700px;
        margin: auto;
      }
    }

    &__car {
      @include mq(sm) {
        order: 2;
      }
      @include mq(sm, only) {
        width: 50%;
      }

      img {
        width: 100%;
      }
    }

    &__info {
      padding: 30px 20px 0;
      text-align: left;

      @include mq(sm) {
        padding: 40px;
        order: 1;
      }

      @include mq(md) {
        padding: 60px;
      }

      @include mq(sm, only) {
        width: 50%;
      }

      ._label {
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 7px;
      }

      ._prize {
        display: block;
        font-size: 30px;
        font-weight: 700;
      }
    }

    &__button {
      @include trans;

      display: inline-block;
      width: 100%;
      max-width: 350px;
      height: 65px;
      background: $mainColor;
      color: $white;
      cursor: pointer;
      line-height: 65px;
      margin-top: 40px;
      border: none;
      text-align: center;
      
      @include mq(sm) {
        width: 275px;
      }

      &:hover {
        color: $white;
        background: $linkHover;
      }

      &:disabled {
        opacity: .5;
        pointer-events: none;
      }
    }

    &__select {
      width: 100%;
      background: none;
      border: none;
      border-bottom: 1px solid $mainColor;
      padding: 0;
      height: 40px;
      max-width: 350px;

      &::placeholder {
        @include trans;
        opacity: .5;
      }

      &:focus {
        outline: none;

        & + .registro__form__label {
          opacity: 1;
          transform: translateY(0);
        }

        &::placeholder {
          opacity: 0;
        }
      }
    }
  }
}

.registro {
  background: $mainColor;

  @include mq(md) {
    display: flex;
  }

  &__hero {
    background: url('/images/main-registro-mobile.jpg') no-repeat center center / cover;
    
    @include mq(md) {
      order: 2;
      width: 60vw;
      background: url('/images/main-registro.jpg') no-repeat center center / cover;
      flex-shrink: 0;
    }

    &:before {
      content: '';
      width: 100%;
      padding-bottom: 100%;
      display: block;
    }

    &__logo {
      position: absolute;
      top: 5vh;
      right: 10%;
      width: 35vw;

      @include mq(md) {
        top: 20vh;
        right: 4%;
        width: 16vw;
      }
    }
  }

  &__sidebar {
    background: $white;
    padding: 30px;

    @include mq(md) {
      order: 1;
      flex-grow: 2;
      padding: 0 80px;
      display: flex;
      align-items: center;
    }
  }

  &__title {
    font-family: $titleFont;
    font-size: 23px;
    line-height: 1.5;
    font-weight: 700;
    color: $mainColor;
    margin: 0 0 20px;

    @include mq(md) {
      font-size: 32px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 500;

    @include mq(md) {
      font-size: 18px;
    }
  }

  &__form {
    padding-top: 60px;

    @include mq(md) {
      padding-top: 15%;
    }

    &__group {
      padding-bottom: 20px;
      padding-top: 10px;
      position: relative;
    }

    &__label {
      @include trans;
      
      font-size: 12px;
      color: $mainColor;
      font-weight: 500;
      opacity: 0;
      display: block;
      position: absolute;
      bottom: calc(100% - 14px);
      transform: translateY(100%);
    }

    &__input {
      width: 100%;
      background: none;
      border: none;
      border-bottom: 1px solid $mainColor;
      padding: 0;
      height: 40px;

      &::placeholder {
        @include trans;
        opacity: .5;
      }

      &:focus {
        outline: none;

        & + .registro__form__label {
          opacity: 1;
          transform: translateY(0);
        }

        &::placeholder {
          opacity: 0;
        }
      }
    }

    &__submit {
      display: block;
      width: 100%;
      height: 65px;
      background: $mainColor;
      color: $white;
      cursor: pointer;
      line-height: 65px;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
      border: none;
      text-align: center;

      @include mq(sm, only) {
        max-width: 300px;
      }

      &:hover {
        color: $white;
        background: $linkHover;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.thanks {
  &__hero {
    background: url('/images/thank-you-hero.jpg') no-repeat center center / cover;
    height: 75vw;

    @include mq(md) {
      height: 30vw;
    }
  }

  &__main {
    padding: 10% 0;
    background: white;
    text-align: center;
    color: $mainColor;

    @include mq(md) {
      padding: 5% 0;
    }
  }

  &__main-title {
    font-weight: 700;
    font-family: $titleFont;
    font-size: 24px;
    line-height: 1.4;
    margin: 0 0 40px;

    @include mq(sm) {
      font-size: 28px;
    }

    @include mq(md) {
      font-size: 40px;
    }
  }

  &__main-subtitle {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 60px;

    @include mq(md) {
      font-size: 18px;
    }
  }
}

.countdown {
  &__hero {
    position: relative;
    height: 43vw;
    overflow: hidden;

    @include mq(md) {
      height: 30vw;
    }

    video,
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%,0);
      width: auto;
      height: 133%;

      @include mq(md) {
          width: 100%;
          height: auto;
      }
    }

    video {
      display: none;

      @include mq(sm) {
        display: block;
      }
    }

    img {
      display: block;

      @include mq(sm) {
        display: none;
      }
    }
  }

  &__clock {
      padding: 80px 0;

    &__title {
        text-align: center;
        margin: 0;
        font-size: 24px;
        margin-bottom: 40px;

        @include mq(md) {
            margin-bottom: 5%;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width: 100%;

        &:after {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            height: 1px;
            background: $darkGray;
            width: 100%;
            top: 50px;
            
            @include mq(md) {
                top: 70px;
            }
        }
        
        @include mq(md) {
            width: 40%;
        }
    }

    &__group {
        padding: 0 10px;

        @include mq(md) {
            padding: 0 20px;
        }
    }

    &__number {
        display: block;
        font-weight: 700;
        font-family: $titleFont;
        margin-bottom: 20px;
        padding-bottom: 20px;
        font-size: 30px;
        text-align: center;
        height: 50px;

        @include mq(md) {
            height: 70px;
            font-size: 50px;
        }
    }

    &__label {
        display: block;
        text-align: center;
        font-size: 12px;

        @include mq(md) {
            font-size: 14px;
        }
    }
  }
}
 