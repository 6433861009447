.header {
    display: flex;
    position: absolute;
    z-index: 1000;
    padding: 20px;
    justify-content: space-between;
    top: 0;
    left: 0;
    right: 0;

    @include mq(sm) {
        padding: 30px 80px;
    }

    &__logo-q8 {
        font-size: 9vw;
        font-family: $titleFont;
        font-weight: 700;
        color: $white;
        line-height: 1;

        @include mq(sm) {
            font-size: 44px;
        }
    }

    &__logo-audi {
        img {
            height: 9vw;
            width: auto;

            @include mq(sm) {
                height: 44px;
            }
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
