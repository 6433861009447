@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
  font-family: 'Audi Type Extended';
  src: url('/fonts/AudiType-ExtendedBold.eot');
  src: url('/fonts/AudiType-ExtendedBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/AudiType-ExtendedBold.woff2') format('woff2'),
      url('/fonts/AudiType-ExtendedBold.woff') format('woff'),
      url('/fonts/AudiType-ExtendedBold.ttf') format('truetype'),
      url('/fonts/AudiType-ExtendedBold.svg#AudiType-ExtendedBold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Audi Type Screen';
  src: url('/fonts/AudiTypeScreen-Normal.eot');
  src: url('/fonts/AudiTypeScreen-Normal.eot?#iefix') format('embedded-opentype'),
      url('/fonts/AudiTypeScreen-Normal.woff2') format('woff2'),
      url('/fonts/AudiTypeScreen-Normal.woff') format('woff'),
      url('/fonts/AudiTypeScreen-Normal.ttf') format('truetype'),
      url('/fonts/AudiTypeScreen-Normal.svg#AudiTypeScreen-Normal') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Audi Type Extended';
  src: url('/fonts/AudiType-ExtendedNormal.eot');
  src: url('/fonts/AudiType-ExtendedNormal.eot?#iefix') format('embedded-opentype'),
      url('/fonts/AudiType-ExtendedNormal.woff2') format('woff2'),
      url('/fonts/AudiType-ExtendedNormal.woff') format('woff'),
      url('/fonts/AudiType-ExtendedNormal.ttf') format('truetype'),
      url('/fonts/AudiType-ExtendedNormal.svg#AudiType-ExtendedNormal') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Audi Type Screen';
  src: url('/fonts/AudiTypeScreen-Bold.eot');
  src: url('/fonts/AudiTypeScreen-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/AudiTypeScreen-Bold.woff2') format('woff2'),
      url('/fonts/AudiTypeScreen-Bold.woff') format('woff'),
      url('/fonts/AudiTypeScreen-Bold.ttf') format('truetype'),
      url('/fonts/AudiTypeScreen-Bold.svg#AudiTypeScreen-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Audi Type Screen';
  src: url('/fonts/AudiTypeScreen-Light.eot');
  src: url('/fonts/AudiTypeScreen-Light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/AudiTypeScreen-Light.woff2') format('woff2'),
      url('/fonts/AudiTypeScreen-Light.woff') format('woff'),
      url('/fonts/AudiTypeScreen-Light.ttf') format('truetype'),
      url('/fonts/AudiTypeScreen-Light.svg#AudiTypeScreen-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}
