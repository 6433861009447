.configuracion {
    &__hero {
        background: url('/images/main-home-mobile.jpg') no-repeat center top / cover;
        height: 75vw;
    
        @include mq(md) {
          height: 27.5vw;
          background: url('/images/main-home.jpg') no-repeat center top fixed / 100%;
        }
    }

    &__content {
        padding: 40px 0;

        @include mq(md) {
            padding: 80px 0;
        }
    }

    &__form {
        display: block;
        max-width: 600px;
        margin: auto;
        width: 100%;
        margin-bottom: 40px;
    }

    &__select {
        width: 100%;
        background: none;
        border: none;
        border-bottom: 1px solid $mainColor;
        padding: 0;
        height: 60px;

        &::placeholder {
            @include trans;
            opacity: .5;
        }

        &:focus {
            outline: none;

            & + .registro__form__label {
            opacity: 1;
            transform: translateY(0);
            }

            &::placeholder {
            opacity: 0;
            }
        }
    }

    &__buttons {
        width: 100%;
        margin-bottom: 40px;
        
        @include mq(md) {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 0;
        }

        &--alt {
            @include mq(md) {
                display: flex;
                max-width: 600px;
                margin: auto;
                align-items: center;
            }

            .configuracion__button {
                display: flex;
                width: auto;
                margin-left: 0;
                line-height: 1;
                align-items: center;
                justify-content: center;
                min-height: 60px;
                height: auto;
                padding: 15px;
                flex-grow: 2;

                &--back {
                    flex-grow: 0;
                }
            }
        }
    }

    &__button {
        display: block;
        text-decoration: none;
        border: 1px solid $black;
        height: 60px;
        line-height: 60px;
        text-align: center;
        width: 100%;
        background: $black;
        color: $white;

        @include mq(md) {
            width: 30%;
            margin-left: 20px;
        }

        &:not(:last-child) {
            margin-bottom: 20px;

            @include mq(md) {
                margin-bottom: 0;
            }
        }

        &:hover {
            color: $white;
            background: $darkestGray;
        }

        span {
            margin-left: 5px;
        }

        &--back {
            margin-right: 15px;
            background: transparent;
            color: $black;

            &:hover {
                color: $black;
                background: $lightGray;
            }

            span {
                margin-left: 0;
                margin-right: 5px;
            }
        }
    }

    &__side-module {
        border: 1px solid $lightGray;
        padding: 20px;

        &__title {
            margin: 0;
            margin-bottom: 5px;
        }

        &__description {
            margin: 0;
            margin-bottom: 20px;
        }

        &__image {
            width: 100%;
            
            img {
                width: 100%;
                height: auto;
                margin-bottom: 20px;
            }
        }

        &__price {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            p {
                margin: 0;
                font-weight: 500;
            }

            span {
                font-weight: 700;
                font-size: 24px;
            }
        }

        &__button {
            border: none;
            background: $black;
            height: 60px;
            line-height: 60px;
            width: 100%;
            text-align: center;
            color: $white;
            font-weight: 700;
            margin-bottom: 20px;
        }

        &__link {
            display: block;
            text-decoration: none;
            font-size: 14px;
            margin: 0;
            
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    &--paso1,
    &--paso2,
    &--paso3,
    &--paso4,
    &--paso5,
    &--paso6,
    &--paso7 {
        &__title {
            margin: 0;
            margin-bottom: 40px;
            font-size: 18px;

            @include mq(md) {
                margin-bottom: 80px;
                font-size: 24px;
            }

            &--paso7 {
                text-align: center;
                font-size: 40px;
                margin-bottom: 40px;
            }
        }

        &__content {
            margin-bottom: 40px;

            @include mq(md) {
                margin-bottom: 80px;
            }
        }

        &__text {
            text-align: center;
            margin: 0;
            
            @include mq(md) {
                width: 70%;
                font-size: 14px;
                margin-left: auto;
                margin-right: auto;

            }
        }

        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-left: 1px solid $lightGray;
            border-right: 1px solid $lightGray;
            border-top: 1px solid $lightGray;
            flex-wrap: wrap;
            padding: 20px;
            height: auto;
            
            @include mq(md) {
                height: 20vh;
                padding: 20px 40px;
            }

            &:last-child {
                border-bottom: 1px solid $lightGray;
            }

            &--paso3,
            &--paso4 {
                border: 1px solid $lightGray;

                &:not(:last-of-type) {
                    margin-bottom: 20px;
                }
            }
        }

        &__wrapper {
            display: flex;
            align-items: center;
            order: 2;
            width: 100%;

            @include mq(md) {
                order: 1;
                width: 45%;
            }
        }

        &__input {
            position: absolute;
            left: -9999px;
            
            &:checked + label {
                &:after {
                    content: '✔';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate3d(-50%, -50%, 0);                    
                }
            }
        }

        &__label {
            position: relative;
            width: 20px;
            height: 20px;
            border: 1px solid $midGray;
            margin-right: 20px;

            &:after {
                @include trans;

                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }

        &__name {
            font-weight: 700;
            margin: 0;
            margin-bottom: 5px;
        }

        &__description {
            margin: 0;
            font-size: 16px;
        }

        &__image {
            order: 1;
            margin-bottom: 20px;
            margin-right: auto;
            margin-left: auto;
            height: 20vh;
            overflow: hidden;
            
            @include mq(md) {
                margin-left: 0;
                height: 100%;
                order: 2;
                margin-bottom: 0;
            }

            img {
                height: 100%;
                width: auto;
            }

            &--paso5,
            &--paso6 {
                @include mq(md) {
                    margin-right: 0;
                }
            }
        }

        &__price {
            font-weight: 700;
            order: 3;
            margin-bottom: 0;
            width: 100%;
            text-align: right;

            @include mq(md) {
                width: auto;
                text-align: left;
            }
        }
    }
}